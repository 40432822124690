import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { mainCategory } from 'src/common/models/category';
import { typography } from 'src/config/typography';
import { colors, shadows } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { CleanButton } from 'src/common/components/Button';
import DotsIcon from 'src/assets/icons/DotsIcon';
import { range } from 'ramda';
import { rem } from 'polished';
import { layers } from 'src/config/layers';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { getLinkToSearchCategory } from 'src/search/routes';
import {
  useCommonCourseCategories,
  usePartnerCourseCategories,
} from 'src/common/hooks/useCommonCourseCategories';
import { UserCoursesRoute } from 'src/user/routes';

const Box = styled.div<{ showAll: boolean }>`
  background: ${colors.white};
  border-radius: 0.25rem;
  box-shadow: ${shadows.drop1};
  height: ${({ showAll }) => (showAll ? 'auto' : rem(77))};
  margin-bottom: -0.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const CategoryList = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
`;

const PartnerCategoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${colors.grey6};
  border-radius: 0.25rem;
`;

const Container = styled.div`
  bottom: 0;
  left: 50%;
  padding: 0 0.75rem;
  max-width: 100%;
  position: fixed;
  transform: translate3d(-50%, 0, 0);
  width: 22.5rem;
  z-index: ${layers.widget};
  -webkit-backface-visibility: hidden;

  ${media.w.min.px576(css`
    width: 33rem;
  `)};

  ${media.w.min.px768(css`
    width: 41.125rem;
  `)};

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const CategoryIcon = styled.svg`
  color: ${colors.blue};
`;

const CategoryItem = styled(CleanButton)`
  flex: 0 0 5.25rem;
  margin: 1.25rem 0 1.1875rem;
  padding: 0 0.3125rem;
  text-align: center;
  white-space: normal;

  &:last-child {
    margin-right: auto;
  }
`;
const PartnerProgramsTile = styled.div`
  display: flex;
  align-items: center;

  white-space: normal;
  text-transform: uppercase;
  color: ${colors.pfp3bis};

  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.08em;
  font-weight: 600;

  ${media.w.min.px576(css`
    flex: 0 0 5.25rem;
    margin: 1.25rem 0 1.1875rem;
    padding-left: 0.625rem;
    text-align: left;
  `)}

  ${media.w.max.px576(css`
    padding-top: 0.625rem;

    flex-basis: 100%;
    width: 100%;
    justify-content: center;
  `)}
`;

const CategoryTitle = styled.span`
  color: ${colors.blue};
  display: block;
  font-family: ${typography.fontFamilyHeaders};
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-top: 0.4375rem;
`;

const EmptyCategory = styled(CategoryItem)`
  margin: 0;
`;

const MainCategory = styled(CategoryItem)`
  order: 0;
`;

const ShowAllButton = styled(CategoryItem)<{ active: boolean }>`
  order: 1;

  ${media.w.min.px360(css`
    order: 2;
  `)};

  ${media.w.min.px576(css`
    order: 4;
  `)};

  ${media.w.min.px768(css`
    order: 5;
  `)};

  svg {
    color: ${({ active }) => (active ? colors.red : 'currentColor')};
  }
`;

export const MobileCategoriesWidget = () => {
  const [showAll, setShowAll] = useState(false);
  const { data: commonCategories } = useCommonCourseCategories();
  const { data: partnerCategories } = usePartnerCourseCategories();

  return (
    <Container>
      <Box showAll={showAll}>
        <PartnerCategoryList>
          <PartnerProgramsTile>programy partnerskie</PartnerProgramsTile>
          {partnerCategories?.map((category, index) => (
            <CategoryItem
              as={GatsbyLink}
              key={category.id}
              style={{ order: index }}
              to={getLinkToSearchCategory(category)}
            >
              <CategoryIcon as={category.icon} size={18} />
              <CategoryTitle dangerouslySetInnerHTML={{ __html: category.title }} />
            </CategoryItem>
          ))}
          {range(0, 4).map((x) => (
            <EmptyCategory key={x} style={{ order: commonCategories?.length ?? 0 }} />
          ))}
        </PartnerCategoryList>

        <CategoryList>
          <MainCategory as={GatsbyLink} to={UserCoursesRoute}>
            <CategoryIcon as={mainCategory.icon} size={18} />
            <CategoryTitle dangerouslySetInnerHTML={{ __html: mainCategory.title }} />
          </MainCategory>

          <ShowAllButton active={showAll} onClick={() => setShowAll(!showAll)}>
            <CategoryIcon as={DotsIcon} size={18} />
            <CategoryTitle
              dangerouslySetInnerHTML={{
                __html: showAll ? 'mniej<br />kategorii' : 'więcej<br />kategorii',
              }}
            />
          </ShowAllButton>

          {commonCategories?.map((category, index) => (
            <CategoryItem
              as={GatsbyLink}
              key={category.id}
              style={{ order: index }}
              to={getLinkToSearchCategory(category)}
            >
              <CategoryIcon as={category.icon} size={18} />
              <CategoryTitle dangerouslySetInnerHTML={{ __html: category.title }} />
            </CategoryItem>
          ))}
          {range(0, 12).map((x) => (
            <EmptyCategory key={x} style={{ order: commonCategories?.length ?? 0 }} />
          ))}
        </CategoryList>
      </Box>
    </Container>
  );
};
