export enum QuestionType {
  Text = 'TEXT',
  Option = 'OPTION',
  MultiOption = 'MULTI_OPTION',
  Scale = 'SCALE',
}

export enum ComparisonOperator {
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  Equal = 'EQUAL',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
}

export interface ChoiceOption {
  enablesComment: boolean;
  id: number;
  text: string;
}

export interface ConditionDTO {
  comparisonOperator: ComparisonOperator;
  comparisonValue: number; // <1,5>
}

export interface QuestionDTO {
  choiceOptions: ChoiceOption[];
  condition: ConditionDTO;

  customOptionAllowed: boolean;
  customOptionLabel: string; // <0,30>
  customOptionPlaceholder: string;
  id: number;
  mandatory: boolean;
  maxLabel?: string; // <0,30>
  minLabel?: string; // <0,30>
  questionType: QuestionType;
  text: string;
}

export interface SurveyDTO {
  courseId: number;
  id: number;
  questions?: QuestionDTO[];
  title: string;
}

export type SurveySchema = SurveyDTO;

export interface AnswerDTO {
  readonly id?: number;
  comment?: string;
  questionId?: number;
  selectedOptionId?: number;
  value?: string;
}

export interface CreateSurveyResponse {
  answers: AnswerDTO[];
  relatedResourceId: number;
  surveyId: number;
}

export enum SurveyStatus {
  Available = 'AVAILABLE',
  Submitted = 'SUBMITTED',
  NotAvailable = 'NOT_AVAILABLE',
}

export enum SurveyUnavailableReason {
  TimeExpired = 'TIME_EXPIRED',
  InvalidEventStatus = 'INVALID_EVENT_STATUS',
  UserNotEligible = 'USER_NOT_ELIGIBLE',
}

export interface SurveyUserStatusDTO {
  surveyAvailabilityStatus: SurveyStatus;
  reason: SurveyUnavailableReason;
}

export interface SurveyAvailabilityDTO {
  expirationDate: Date;
  surveyUserStatus: SurveyUserStatusDTO;
}
