import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const DotsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 39.1114c-3.9274 0-7.1111-3.1838-7.1111-7.1111 0-3.9274 3.1837-7.1111 7.1111-7.1111 3.9274 0 7.1111 3.1837 7.1111 7.1111 0 3.9273-3.1837 7.1111-7.1111 7.1111z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M7.11111 39.1114C3.18375 39.1114 0 35.9276 0 32.0003c0-3.9274 3.18375-7.1111 7.11111-7.1111 3.92739 0 7.11109 3.1837 7.11109 7.1111 0 3.9273-3.1837 7.1111-7.11109 7.1111z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M56.8889 39.1114c-3.9274 0-7.1111-3.1838-7.1111-7.1111 0-3.9274 3.1837-7.1111 7.1111-7.1111 3.9273 0 7.1111 3.1837 7.1111 7.1111 0 3.9273-3.1838 7.1111-7.1111 7.1111z"
    />
  </IconBox>
);

export default DotsIcon;
