import React, { FC } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'src/config/colors';

// https://github.com/mui-org/material-ui/issues/11467
const TooltipProxy: FC<TooltipProps> = ({ children, ...props }) => (
  <MuiTooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props}>
    {children}
  </MuiTooltip>
);

export const Tooltip = styled(TooltipProxy)`
  padding: 0.75rem;

  & .tooltip {
    background-color: ${colors.blue};
    border-radius: 0.375rem;
    color: ${colors.white};
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.0625rem;
    max-width: 29.6875rem;
    padding: 0;
    pointer-events: all;

    & .MuiTooltip-arrow {
      height: auto;
      width: auto;

      &::before {
        background-color: transparent;
        border: 0.25rem solid transparent;
        height: 0;
        transform: none;
        width: 0;
      }
    }

    &.MuiTooltip-tooltipPlacementTop {
      margin-bottom: 0.1875rem;

      & .MuiTooltip-arrow {
        margin-bottom: -0.5rem;

        &::before {
          border-top-color: ${colors.blue};
        }
      }
    }

    &.MuiTooltip-tooltipPlacementRight {
      margin-left: 0.1875rem;

      & .MuiTooltip-arrow {
        margin-left: -0.5rem;

        &::before {
          border-right-color: ${colors.blue};
        }
      }
    }

    &.MuiTooltip-tooltipPlacementBottom {
      margin-top: 0.1875rem;

      & .MuiTooltip-arrow {
        margin-top: -0.5rem;

        &::before {
          border-bottom-color: ${colors.blue};
        }
      }
    }

    &.MuiTooltip-tooltipPlacementLeft {
      margin-right: 0.1875rem;

      & .MuiTooltip-arrow {
        margin-right: -0.5rem;

        &::before {
          border-left-color: ${colors.blue};
        }
      }
    }
  }
`;
