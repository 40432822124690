import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { CourseCardBox, StyledSmallCtaIcon } from 'src/common/components/CourseCard/CourseCardBox';
import {
  CourseCardThemeParams,
  generateDetailsLink,
  generateSurveyLink,
} from 'src/common/components/CourseCard/config';
import { widthBreakpoints } from 'src/config/breakpoints';
import { CardSurveyOverlay } from 'src/common/components/CardSurveyOverlay';
import { FeaturedButtonType } from 'src/common/components/CourseCard/CourseCardContent';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { SurveyStatus } from 'src/common/models/survey';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { RecordingStatus } from 'src/common/models/event';
import { differenceInHours } from 'date-fns';
import { getUserCourseRecordingRoute } from 'src/user/routes';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const StyledLink = styled(GatsbyLink)`
  display: flex;
  flex: 1 1 auto;
  text-decoration: none;
  width: 100%;

  &:hover {
    ${StyledSmallCtaIcon} {
      opacity: 1;
    }
  }
`;

const RecordingCTA = styled(Button).attrs({ kind: 'primary' })`
  text-align: center;
  padding: calc(0.5625rem + 0.375rem) 0.9375rem 0.5625rem;
  background: ${colors.red};
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  margin-top: -0.375rem;

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${colors.white};
  width: 100%;
`;

export interface CourseCardProps {
  featured?: boolean;
  featuredBreakpoint?: keyof typeof widthBreakpoints;
  largeMobileImage?: boolean;
  featuredButton?: FeaturedButtonType;
  survey?: { status: SurveyStatus; availableUntil: Date };
  courseExcerpt: CourseExcerpt;
  recording?: { status: RecordingStatus; availableUntil: Date };
  elearningProgressBar?: boolean;
}

export const CourseCard: FC<CourseCardProps> = ({
  featured = false,
  featuredBreakpoint,
  largeMobileImage,
  survey,
  featuredButton = 'primary',
  courseExcerpt,
  recording,
  elearningProgressBar,
}) => {
  const themeParams: CourseCardThemeParams = {
    featured,
    featuredBreakpoint,
    largeMobileImage,
    elearningProgressBar,
  };

  return (
    <ThemeProvider theme={themeParams}>
      <Container>
        {featured ? (
          <CourseCardBox
            featured={featured}
            featuredBreakpoint={featuredBreakpoint}
            featuredButton={featuredButton}
            courseExcerpt={courseExcerpt}
          />
        ) : (
          <StyledLink to={generateDetailsLink(courseExcerpt)}>
            <CourseCardBox
              featured={featured}
              featuredBreakpoint={featuredBreakpoint}
              courseExcerpt={courseExcerpt}
            />
          </StyledLink>
        )}

        {survey?.status === SurveyStatus.Available && (
          <CardSurveyOverlay survey={survey} link={generateSurveyLink(courseExcerpt)} />
        )}

        {recording?.status === RecordingStatus.Available && courseExcerpt && (
          <RecordingCTA
            as={GatsbyLink}
            to={getUserCourseRecordingRoute(
              courseExcerpt.courseId?.toString(),
              courseExcerpt.id?.toString() ?? '',
            )}
          >
            Wideo szkolenia (dostępne {differenceInHours(recording.availableUntil, new Date())}h)
          </RecordingCTA>
        )}
      </Container>
    </ThemeProvider>
  );
};
