import styled from 'styled-components';
import React, { FC } from 'react';
import { colors } from 'src/config/colors';
import { StyleProps } from 'src/lib/styleProps';

export const Container = styled.div`
  position: relative;
  margin-top: 1.625rem;,
  margin-bottom: 0.4375rem;
  white-space: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    width: 4.625rem;
  }
`;

const LeftPanelCategoryGroupHeadingLine = styled.div`
  width: 4.225rem;
  border-bottom: 1px solid ${colors.darkGrey};
  flex: 1 1 1px;
`;

const LeftPanelCategoryGroupHeading = styled.div`
  position: relative;
  color: ${colors.pfp3bis};
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  font-weight: 600;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  max-width: 180px;
  width: min-content;
`;

export const LeftPanelCategoryGroupHeader: FC<StyleProps> = ({ className, style, children }) => (
  <Container className={className} style={style}>
    <LeftPanelCategoryGroupHeadingLine />
    <LeftPanelCategoryGroupHeading>{children}</LeftPanelCategoryGroupHeading>
    <LeftPanelCategoryGroupHeadingLine />
  </Container>
);
