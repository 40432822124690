import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { LogoLeftPanel } from 'src/common/images/LogoLeftPanel';
import { HomepageRoute } from 'src/public/publicRoutes';
import { LeftPanelCategory } from 'src/common/components/LeftPanelCategory';
import { mainCategory } from 'src/common/models/category';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActivePanelCategorySelector } from 'src/state/layout/layoutSelectors';
import { layoutActions } from 'src/state/layout/layoutActions';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import {
  useCommonCourseCategories,
  usePartnerCourseCategories,
} from 'src/common/hooks/useCommonCourseCategories';
import { PartnerLogoDesktop } from 'src/common/components/PartnerLogoDesktop';
import { LeftPanelCategoryGroupHeader } from 'src/common/components/LeftPanelCategoryGroupHeader';
import { UserCoursesRoute } from 'src/user/routes';

const Container = styled.div`
  width: 100%;
`;

const StyledLink = styled(GatsbyLink)`
  display: inline-block;
  margin: 2.375rem 0 1.5625rem 2.125rem;
`;

export const LayoutPanelCategories: FC = () => {
  const activeCategory = useSelector(layoutActivePanelCategorySelector);
  const dispatch = useDispatch();
  const { data: commonCategories } = useCommonCourseCategories();
  const { data: partnerCategories } = usePartnerCourseCategories();

  useEffect(() => {
    return () => {
      dispatch(layoutActions.setActivePanelCategory(null));
    };
  }, []);

  return (
    <Container>
      <StyledLink to={HomepageRoute}>
        <LogoLeftPanel />
      </StyledLink>

      <PartnerLogoDesktop />

      <LeftPanelCategory category={mainCategory} url={UserCoursesRoute} />

      {commonCategories?.map((category) => (
        <LeftPanelCategory
          active={activeCategory === String(category.id)}
          category={category}
          key={category.id}
        />
      ))}

      {partnerCategories?.length > 0 && (
        <>
          <LeftPanelCategoryGroupHeader>Programy partnerskie</LeftPanelCategoryGroupHeader>
          {partnerCategories?.map((category) => (
            <LeftPanelCategory
              active={activeCategory === String(category.id)}
              category={category}
              key={category.id}
            />
          ))}
        </>
      )}
    </Container>
  );
};
