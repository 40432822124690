import React, { FC } from 'react';
import { Category } from 'src/common/models/category';
import { LeftPanelIcon, LeftPanelItem, LeftPanelTitle } from 'src/common/components/LeftPanelItem';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { getLinkToSearchCategory } from 'src/search/routes';

export interface LeftPanelCategoryProps {
  active?: boolean;
  category: Category;
  url?: string;
}

export const LeftPanelCategory: FC<LeftPanelCategoryProps> = ({
  active = false,
  category,
  url,
}) => (
  <LeftPanelItem
    $active={active}
    as={GatsbyLink}
    key={category.id}
    to={url || getLinkToSearchCategory(category)}
  >
    <LeftPanelIcon as={category.icon} size={18} />
    <LeftPanelTitle dangerouslySetInnerHTML={{ __html: category.title }} />
  </LeftPanelItem>
);
