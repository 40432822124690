import React, { FC } from 'react';
import styled from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import CloseIcon from 'src/assets/icons/CloseIcon';

const CloseButton = styled(CleanButton)`
  padding: 0.3125rem;
  position: absolute;
  right: 0.3125rem;
  top: 0.3125rem;

  svg {
    display: block;
  }
`;

const Container = styled.div`
  padding: 1rem 2.625rem 1rem 1rem;
  position: relative;
`;

export interface TooltipContentProps {
  onClose: () => void;
}

export const TooltipContent: FC<TooltipContentProps> = ({ children, onClose }) => (
  <Container>
    {children}

    <CloseButton onClick={onClose}>
      <CloseIcon size={12} />
    </CloseButton>
  </Container>
);
