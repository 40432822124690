import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { rgba } from 'polished';
import { typography } from 'src/config/typography';
import { Button, CleanButton } from 'src/common/components/Button';
import LightbulbIcon from 'src/assets/icons/LightbulbIcon';
import { Tooltip } from 'src/common/components/Tooltip';
import { TooltipContent } from 'src/common/components/Tooltip/TooltipContent';
import { ClickAwayListener } from '@material-ui/core';
import { layers } from 'src/config/layers';
import { SurveyStatus } from 'src/common/models/survey';
import { differenceInDays } from 'date-fns';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Info = styled(CleanButton)`
  font-size: 0.625rem;
  line-height: 0.875rem;
  margin-top: 3.625rem;

  svg {
    margin-bottom: 0.25rem;
  }
`;

const Overlay = styled.div`
  align-items: center;
  background: ${rgba(colors.white, 0.92)};
  bottom: 0;
  color: ${colors.blue};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  padding: 1.875rem 2.75rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: ${layers.overlay};
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 12.5rem;
`;

const Text = styled.div`
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  line-height: 1.375rem;
`;

export interface CardSurveyOverlayProps {
  link: string;
  survey: { status: SurveyStatus; availableUntil: Date };
}

export const CardSurveyOverlay: FC<CardSurveyOverlayProps> = ({ link, survey }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Overlay>
      <Title>Gratulacje!</Title>
      <Text>Jeszcze tylko jeden krok. Wypełnij ankietę i&nbsp;pobierz Twój certyfikat.</Text>
      <Button as={GatsbyLink} to={link}>
        Wypełnij ankietę
      </Button>

      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <div>
          <StyledTooltip
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={showTooltip}
            placement="top"
            title={
              <TooltipContent onClose={() => setShowTooltip(false)}>
                Pamiętaj! Po upływie ważności ankiety, nie będzie możliwości pobrania certyfikatu.
              </TooltipContent>
            }
          >
            <Info
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => setShowTooltip(true)}
            >
              <LightbulbIcon size={18} />
              <div>
                Ankieta ważna jeszcze {differenceInDays(survey.availableUntil, new Date())}&nbsp;dni
              </div>
            </Info>
          </StyledTooltip>
        </div>
      </ClickAwayListener>
    </Overlay>
  );
};
