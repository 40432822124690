import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { Button } from 'src/common/components/Button';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const EmptyCard = styled.div`
  background: ${colors.white};
  box-shadow: ${shadows.drop1};
  border-radius: 0.375rem;
  height: 23rem;
  justify-content: center;
  text-align: center;
  width: 13.25rem;
`;

const EmptyCardText = styled.div`
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 4.875rem 2.5rem 2.6875rem;
`;

export interface EmptyCourseCardProps {
  url: string;
  urlState?: unknown;
}

export const EmptyCourseCard: FC<EmptyCourseCardProps> = ({ url, urlState }) => (
  <EmptyCard>
    <EmptyCardText>Chcesz zobaczyć więcej szkoleń?</EmptyCardText>
    <Button as={GatsbyLink} to={url} state={urlState} kind="border">
      Pokaż więcej szkoleń
    </Button>
  </EmptyCard>
);
