import styled from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { colors, shadows } from 'src/config/colors';

export interface LeftPanelItemProps {
  $active: boolean;
}

export const LeftPanelIcon = styled.svg`
  flex: 0 0 auto;
  margin-right: 1.375rem;
`;

export const LeftPanelItem = styled(CleanButton).attrs<LeftPanelItemProps>(({ $active }) => ({
  className: $active && 'is-active',
}))<LeftPanelItemProps>`
  align-items: center;
  border-radius: 0.25rem;
  color: ${colors.blue};
  display: flex;
  margin-right: -1.5rem;
  padding: 0.9375rem 1.5rem 0.9375rem 2.125rem;
  text-align: left;
  white-space: normal;
  width: calc(100% + 1.5rem);

  &:hover,
  &.is-active {
    background: ${colors.white};
    box-shadow: ${shadows.drop1};
    margin-top: -0.125rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    top: 0.0625rem;

    ${LeftPanelIcon} {
      color: ${colors.red};
    }
  }
`;

export const LeftPanelTitle = styled.span`
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  padding-right: 2.5rem;
`;
